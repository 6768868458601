import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";

import "./about.scss";

const About = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "about.about-title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={[
                    `brief therapy`,
                    `San Francisco`,
                    `Psychotherapy`,
                    `MFT`,
                ]}
                lang={props.pageContext.locale}
            />
            <article className="about">
                <header className="about__header">
                    <GatsbyImage
                        image={
                            props.data.fileName.childImageSharp.gatsbyImageData
                        }
                        alt=""
                    />
                    <h1>
                        <FormattedMessage id="about.about-title" />
                    </h1>
                </header>
                <main className="about__main">
                    <section className="about__gloria">
                        <p>
                            <FormattedMessage
                                id="about.about-story-one"
                                values={{
                                    "link-one": (
                                        <a
                                            href="https://brieftherapycenter.org"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Brief Therapy Center (BTC)
                                        </a>
                                    ),
                                    "link-two": (
                                        <a
                                            href="https://mri.org"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Mental Research Institute (MRI)
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-story-two"
                                values={{
                                    "link-karin": (
                                        <a
                                            href="https://www.karinschlanger.com/"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Karin Schlanger
                                        </a>
                                    ),
                                    "link-one": (
                                        <a
                                            href="https://brieftherapycenter.org"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Brief Therapy Center (BTC)
                                        </a>
                                    ),
                                    "link-two": (
                                        <a
                                            href="https://mri.org"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Mental Research Institute (MRI)
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-story-three"
                                values={{
                                    "link-one": (
                                        <a
                                            href="https://www.brieftherapycenter.org/"
                                            target="_blank"
                                            rel="nofollow noopener noreferrer"
                                        >
                                            Brief Therapy Center (BTC)
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-story-four"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage id="about.about-story-five" />
                        </p>
                        <p>
                            <FormattedMessage id="about.about-story-six" />
                        </p>
                        <span id="themodel"></span>
                    </section>
                    <section className="about__model">
                        <h2>
                            <FormattedMessage id="about.about-model-title" />
                        </h2>
                        <blockquote>
                            <FormattedMessage id="about.about-model-quote" />
                        </blockquote>
                        <p>
                            <FormattedMessage
                                id="about.about-model-one"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-model-two"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-model-three"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="about.about-model-four"
                                values={{
                                    strongIn: <strong>, strongOut: </strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage id="about.about-model-five" />
                        </p>
                    </section>
                </main>
            </article>
        </div>
    );
};

const customProps = {
    localeKey: "about",
};

export default withLayout(customProps)(injectIntl(About));

export const pageQuery = graphql`
    {
        fileName: file(relativePath: { eq: "gloria-diaz-backyard-focus.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 800, height: 533, layout: CONSTRAINED)
            }
        }
    }
`;
